/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {

                //wow.js initialize - Don't forget to uncomment the enqueue in lib/setup.php at the bottom!
                new WOW().init();

                $(function(){$.fn.scrollToTop=function(){$(this).hide().removeAttr("href");if($(window).scrollTop()!="0"){$(this).fadeIn("slow")}var scrollDiv=$(this);$(window).scroll(function(){if($(window).scrollTop()=="0"){$(scrollDiv).fadeOut("slow")}else{$(scrollDiv).fadeIn("slow")}});$(this).click(function(){$("html, body").animate({scrollTop:0},"slow")})}});

                $(function(){
                    $("#toTop").scrollToTop();
                });

                $(function(){
                    $('.dropdown').hover(function() {
                            $(this).addClass('open');
                        },
                        function() {
                            $(this).removeClass('open');
                        });
                    });
                
                jQuery(document).ready( function($) {

                    $('label.switch').click(function(event){

                        var childwrap = $('#child-wrap');
                        var adultwrap = $('#adult-wrap');


                        if($(childwrap).hasClass('hidden-tiles'))
                        {
                            $(adultwrap).addClass('hidden-tiles');
                            $(adultwrap).hide();
                            $(childwrap).removeClass('hidden-tiles');
                            $(childwrap).show();
                        }
                        else {
                            $(childwrap).addClass('hidden-tiles');
                            $(childwrap).hide();
                            $(adultwrap).removeClass('hidden-tiles');
                            $(adultwrap).show();
                        }


                        $('label.switch input.checkbox').prop("checked", !$('label.switch input.checkbox').prop("checked"));

                        return false;

                    });


                });

            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    $(function () {
        $(document).scroll(function () {
            var $nav = $("#topnav");
            $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        });
    });

})(jQuery); // Fully reference jQuery after this point.